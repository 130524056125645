import React from "react";
import { Panel, Window, WindowHeader } from "react95";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import { links } from '../data/homepageLinks';
import { iconStyle } from "../styles";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout
      subtitle={'Home'}
      description={'Muscarian Softworks: independent game and interactive web developer since 2018'}
    >
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <Window style={{ maxWidth: '100%' }}>
          <WindowHeader as="h2" className="window-header-icon">
            <StaticImage
              alt=""
              aria-hidden="true"
              src="../images/computer.png"
              style={iconStyle}
            />
            <span>About Muscarian Softworks</span>
          </WindowHeader>
          <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
            <p className="page-copy-container">
            Muscarian Softworks is a one-person studio specializing in microgames, immersive web experiences, and tools to create them. Founded in 2018, the studio has released <Link to="/games">several small games and prototypes</Link>.<br/><br/>
            <Panel variant="inside" className="index-pic-container-panel">
              <StaticImage
                alt="a pixelated cityscape with mushroom towers and a dirigible"
                className="crt"
                src="../images/mushroom_towers.jpeg"
              />
            </Panel><br/>
            The studio also has several unreleased games in development, such as "Mnemosyne," most aptly described as "a multiplayer web-based portal to a post-post-apocalyptic crystalline fungal hell-zone", and several concepts based on previous jam games. <br /><br />
            Muscarian Softworks is currently operated by a solo developer/designer, with occasional consulting and commission work from other talented individuals. Our goal is to create unique, meaningful games and immersive experiences while also experimenting with new technologies and cultivating a friendly community.<br /><br />
            The <Link to="/blog">blog </Link> features posts offering insight into current works-in-development, post-mortems of existing games, and posts about game development in general, as well as some analysis and review of others' games.<br /><br />
            </p>
          </div>
        </Window>
        <ul className="social-icons-list">
            {links.map(link => (
              <li
                key={link.url}
              >
                <a className="social-icon-button" href={link.url} target="_blank" rel="noreferrer noopener">
                  {link.icon}
                </a>
              </li>
            ))}
          </ul>
      </div>
    </Layout>
  );
}

export default IndexPage
